import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import routes from "app/configs/routesConfig";
import axiosClient from "app/configs/axiosClientConfig";
import MessageConstant from "src/app/constants/messageConstant";
import URLConstant from "src/app/constants/urlConstant";
import DataConstant from "src/app/constants/dataConstant";
import {
    Button,
    FormLabel,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography
} from "@mui/material";

const AppStorePage = (props) => {
    const { data } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const [shorthandUrl, setShorthandUrl] = useState("Dynamic");
    const [linkError, setLinkError] = useState("");

    var subscriptionPlanType = user?.InvoiceInfo?.InvoiceType;
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch
    } = useForm();

    useEffect(() => {
        if (data) {
            setValue("name", data.name);
            setValue("appStore", data.appStoreLink);
            setValue("playStore", data.playStoreLink);
            setValue("other", data.otherLink);
            setValue("code",data.code);
        }
        else {
            GetShortHandUrl();
        }
    }, [data]);

    const isValidAppStoreLink = (value) => {
        const appStoreRegex = /^https?:\/\/(apps\.apple\.com)/;
        return appStoreRegex.test(value);
    };

    const isValidPlayStoreLink = (value) => {
        const playStoreRegex = /^https?:\/\/play\.google\.com/;
        return playStoreRegex.test(value);
    }

    const isValidOtherLink = (value) => {
        const otherLinkRegex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
        return otherLinkRegex.test(value);
    };

    const redirectToQRCode = () => {
        navigate(`/qrcodes`);
    };

    const GetShortHandUrl = () => {
        axiosClient
            .get(`${URLConstant.QRCode.GetShortHandUrl}`)
            .then((response) => {
                if (response) {
                    setShorthandUrl(response.data.qrCode);
                    setValue("code", response.data.qrCode);
                }
            });
    };

    const isMatchWithRoute = (value) => {
        if (!value) return false;
        const allPaths = routes.map(route => route.path);

        const isMatch = allPaths.some(route => {
            const segments = route.split("/");
            const initial = segments[0] || segments[1];
            if (value?.toLowerCase() === initial?.toLowerCase() || value?.toLowerCase() === segments[1]?.toLowerCase()) {
                return true;
            }
        });
        return isMatch;
    };

    const validateLinks = (data) => {
        if (!data.appStore && !data.playStore && !data.other) {
            setLinkError(MessageConstant.LinkValidation);
            return false;
        }
        setLinkError("");
        return true;
    };

    const onFinish = (appstoreDetails) => {
        if (!validateLinks(appstoreDetails)) {
            return;
        }

        const code = appstoreDetails?.code;

        if (isMatchWithRoute(code)) {
            notification.warning({
                message: DataConstant.Warning,
                description: MessageConstant.CodeRestricted,
            });
            return;
        }

        const modelToSubmit = {
            id: id ? id : "",
            Name: appstoreDetails?.name.trim(),
            Type: DataConstant.qrcodeType.appStore,
            SubTypes: "Dynamic",
            Url: "",
            code:shorthandUrl? appstoreDetails?.code : null,
            SubscriptionCurrentPaymentType: subscriptionPlanType,
            AppStoreLink: appstoreDetails.appStore,
            PlayStoreLink: appstoreDetails.playStore,
            OtherLink: appstoreDetails.other
        };


        const requestURL = id
            ? URLConstant.QRCode.UpdateUrlQRCode
            : URLConstant.QRCode.AddQRCodes;
        axiosClient.post(requestURL, modelToSubmit).then((response) => {
            if (response && _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.SUCCESS)) {
                notification.success({
                    message: DataConstant.Save,
                    description: response?.message,
                });
                redirectToQRCode();
            } else if (response && _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.WARNING)) {
                notification.warning({
                    message: DataConstant.Warning,
                    description: response?.message,
                });
            } else {
                notification.error({
                    message: DataConstant.error,
                    description: response?.message,
                });
            }
        });
    }

    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(onFinish)}
            >
                <div className="px-16">
                    <FormLabel className="mt-16 ">Name</FormLabel>
                    <span className="text-[#ff0000] text-lg-start"> *</span>
                    <TextField
                        id="name"
                        name="name"
                        className="w-full my-8"
                        placeholder="QRcode name"
                        {...register("name", {
                            required: "Please enter name",
                            maxLength: {
                                value: 60,
                                message: MessageConstant.EnterMaximum60Char,
                            },
                        })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                    <FormLabel className="mt-16">App Store Link</FormLabel>
                    <TextField
                        id="appStore"
                        name="appStore"
                        className="w-full my-8"
                        placeholder="Appstore link"
                        {...register("appStore", {
                            validate: value => !value || isValidAppStoreLink(value) || MessageConstant.EnterValidAppStoreURL
                        })}
                        error={!!errors.appStore}
                        helperText={errors.appStore?.message}
                    />
                    <FormLabel className="mt-16">Play Store Link</FormLabel>
                    <TextField
                        id="playStore"
                        name="playStore"
                        className="w-full my-8"
                        placeholder="Playstore link"
                        {...register("playStore", {
                            validate: value => !value || isValidPlayStoreLink(value) || MessageConstant.EnterValidPlayStoreURL
                        })}
                        error={!!errors.playStore}
                        helperText={errors.playStore?.message}
                    />
                    <FormLabel className="mt-16">Other Device Link</FormLabel>
                    <TextField
                        id="other"
                        name="other"
                        className="w-full my-8"
                        placeholder="Other device link"
                        {...register("other", {
                            validate: value => !value || isValidOtherLink(value) || MessageConstant.EnterValidURL
                        })}
                        error={!!errors.other}
                        helperText={errors.other?.message}
                    />
                    {linkError && <Typography color="error">{linkError}</Typography>}
                    <div>
                        <FormLabel className="mt-16">Shorthand URL</FormLabel>
                        <span className="text-[#ff0000] text-lg-start"> *</span>
                        <div className="flex">
                            <TextField
                                value={URLConstant.OriginUrl}
                                maxLength="50"
                                className="w-1/4 my-8"
                                disabled
                            />
                            <TextField
                                id="shorthandUrl"
                                name="shorthandUrl"
                                maxLength="50"
                                className="w-3/4 my-8"
                                placeholder="URL code"
                                {...register("code", {
                                    required: MessageConstant.EnterCode,
                                    pattern: {
                                        value: DataConstant.urlCodeRegex,
                                        message: DataConstant.urlCodeMessage,
                                    }
                                })}
                                error={!!errors.code}
                                helperText={
                                    <span className="absolute">{errors.code?.message}</span>
                                }
                                disabled={data != null && data != ""}
                            />
                        </div>
                        <div>
                            <label className="url-preview"><span>Preview:</span><br /> {URLConstant.OriginUrl}/{watch("code")}</label>
                        </div>
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="my-8 flex"
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </>
    );
};

export default AppStorePage;
