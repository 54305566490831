import i18next from "i18next";
import en from "./i18n/en";
import tr from "./i18n/tr";
import ar from "./i18n/ar";
import React from "react";
import QRCode from "./QrCode";
import ManageQRCode from "./ManageQRCode";
import QrCodeScanLog from "./QrCodeScanLog";

i18next.addResourceBundle("en", "companyPage", en);
i18next.addResourceBundle("tr", "companyPage", tr);
i18next.addResourceBundle("ar", "companyPage", ar);

const QrCodeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "qrcodes",
      element: <QRCode />,
    },
    {
      path: "manageqrcode",
      element: <ManageQRCode />,
    },
    {
      path: "qrcodescanlog",
      element: <QrCodeScanLog />,
    },
    {
      path: "qrcodescanlog/:id",
      element: <QrCodeScanLog />,
    },
    {
      path: "manageqrcode/:id",
      element: <ManageQRCode />,
    },
  ],
};

export default QrCodeConfig;
