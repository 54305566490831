import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ApexChart from 'react-apexcharts'
import DataConstant from 'src/app/constants/dataConstant';
import QrChartDetail from './QrChartDetail';
import { Empty } from 'antd';

const ChartComponent = (props) => {

  const [seriesData, setSeriesData] = useState([]);
  const [chartTitle, setChartTitle] = useState("");
  const [xaxisTitle, setXaxisTitle] = useState("");
  const [yaxisTitle, setYaxisTitle] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [chartLabel, setchartLabel] = useState([]);
  const [qrDetail, setQrDetail] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const dataset = {
    series: seriesData,
    options: {
      chart: {
        height: props.Height,
        type: props.ChartType,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          },
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 6,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      labels: chartLabel,
      colors: ['#77B6EA', '#E65F8E', '#A86BD1', '#3AA5D1', '#3BB58F'],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          if(props.ChartType == DataConstant.ChartDetail.Type.Donut)
            return opts.w.config.series[opts.seriesIndex]
          else
            return val
        },
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: chartTitle,
        align: 'left',
        margin: 10,
        style: {
          fontWeight:  'bold',
          fontFamily:  'Inter var, Roboto, Helvetica, Arial, sans-serif',
          color:  '#263238'
        },
      },
      xaxis: {
        categories: categoryData,
        title: {
          text: xaxisTitle
        }
      },
      yaxis: {
        title: {
          text: yaxisTitle
        },
      },
      legend: {
        formatter: function(seriesName, opts) {
          if (seriesName.length > 10) { 
            return seriesName.slice(0, 10) + '...';
          }
          return seriesName;
        }
      }
    },
  }

  useEffect(() => {
    GetChartData();
  }, [])

  const GetChartData = () => {
    switch(props.Category){
      case DataConstant.ChartDetail.Category.CHART_DATEWISE :
        const dateWiseData = props.Data?.filter(x => x.chartName == DataConstant.ChartDetail.Category.CHART_DATEWISE)
        const dateCount = dateWiseData.map(x => x.y1);
        const dates = dateWiseData.map(x => x.x1);
        if(dateCount == null || dateCount == undefined || dateCount.length == 0) setIsEmpty(true)
        setSeriesData([{ name : "Scan Count", data: dateCount }]);
        setChartTitle(DataConstant.ChartDetail.Title.DateWiseScanCount);
        setCategoryData(dates);
        setXaxisTitle("Date");
        setYaxisTitle("Scan Count");
        break;
      case DataConstant.ChartDetail.Category.CHART_DEVICEWISE :
        const deviceWiseData = props.Data?.filter(x => x.chartName == DataConstant.ChartDetail.Category.CHART_DEVICEWISE)
        const deviceCount = deviceWiseData.map(x => x.y1);
        const devices = deviceWiseData.map(x => x.x1);
        if(deviceCount == null || deviceCount == undefined || deviceCount.length == 0) setIsEmpty(true)
        setSeriesData([{ name : "Scan Count", data: deviceCount }]);
        setChartTitle(DataConstant.ChartDetail.Title.DeviceWiseScanCount);
        setCategoryData(devices);
        setXaxisTitle("Scan Count");
        setYaxisTitle("Device");
        break;
      case DataConstant.ChartDetail.Category.CHART_TYPEWISETOP5 :
        const typeWiseData = props.Data?.filter(x => x.chartName == DataConstant.ChartDetail.Category.CHART_TYPEWISETOP5)
        const typeCount = [0,0,0,0,0,0];
        const types = ["Url", "Pdf", "Landing Page", "WhatsApp", "Instagram", "App Store"];
        typeWiseData?.forEach(element => {
          if(element?.x2 == DataConstant.qrcodeType.url) typeCount[0] += element.y1
          if(element?.x2 == DataConstant.qrcodeType.pdf) typeCount[1] += element.y1
          if(element?.x2 == DataConstant.qrcodeType.landingPageQR) typeCount[2] += element.y1
          if(element?.x2 == DataConstant.qrcodeType.whatsapp) typeCount[3] += element.y1
          //if(element?.x2 == DataConstant.qrcodeType.wifi) typeCount[4] += element.y1
          if(element?.x2 == DataConstant.qrcodeType.instagram) typeCount[4] += element.y1
          if(element?.x2 == DataConstant.qrcodeType.appStore) typeCount[5] += element.y1
        });
        const chartDetails = [
          { label : types[0], detail : typeWiseData?.filter(x => x.x2 == DataConstant.qrcodeType.url)?.map(x => { return {name : x.x1 , value: x.y1}})},
          { label : types[1], detail : typeWiseData?.filter(x => x.x2 == DataConstant.qrcodeType.pdf)?.map(x => { return {name : x.x1 , value: x.y1}})},
          { label : types[2], detail : typeWiseData?.filter(x => x.x2 == DataConstant.qrcodeType.landingPageQR)?.map(x => { return {name : x.x1 , value: x.y1}})},
          { label : types[3], detail : typeWiseData?.filter(x => x.x2 == DataConstant.qrcodeType.whatsapp)?.map(x => { return {name : x.x1 , value: x.y1}})},
          //{ label : types[4], detail : typeWiseData?.filter(x => x.x2 == DataConstant.qrcodeType.wifi)?.map(x => { return {name : x.x1 , value: x.y1}})},
          { label : types[4], detail : typeWiseData?.filter(x => x.x2 == DataConstant.qrcodeType.instagram)?.map(x => { return {name : x.x1 , value: x.y1}})},
          { label : types[5], detail : typeWiseData?.filter(x => x.x2 == DataConstant.qrcodeType.appStore)?.map(x => { return {name : x.x1 , value: x.y1}})}
        ];
        if(typeWiseData == null || typeWiseData == undefined || typeWiseData.length == 0) setIsEmpty(true)
        setSeriesData(typeCount);
        setchartLabel(types);
        setChartTitle(DataConstant.ChartDetail.Title.TypeWiseTop5QrcodeScanCount);
        setQrDetail(chartDetails);
        break;
      case DataConstant.ChartDetail.Category.CHART_TAGWISE :
        const tagWiseData = props.Data?.filter(x => x.chartName == DataConstant.ChartDetail.Category.CHART_TAGWISE)
        const tagCount = tagWiseData.map(x => x.y1);
        const tags = tagWiseData.map(x => x.x1);
        if(tagCount == null || tagCount == undefined || tagCount.length == 0) setIsEmpty(true)
        setSeriesData(tagCount);
        setchartLabel(tags);
        setChartTitle(DataConstant.ChartDetail.Title.TagWiseScanCount);
        break;
      case DataConstant.ChartDetail.Category.CHART_TOP10QRCODE :
        const topQrWiseData = props.Data?.filter(x => x.chartName == DataConstant.ChartDetail.Category.CHART_TOP10QRCODE)
        const qrCount = topQrWiseData.map(x => x.y1);
        const qrCodes = topQrWiseData.map(x => x.x1);
        if(qrCount == null || qrCount == undefined || qrCount.length == 0) setIsEmpty(true)
        setSeriesData(qrCount);
        setchartLabel(qrCodes);
        setChartTitle(DataConstant.ChartDetail.Title.Top10QrCodeScanCount);
        break;
      case DataConstant.ChartDetail.Category.CHART_CITYWISE :
        const cityWiseData = props.Data?.filter(x => x.chartName == DataConstant.ChartDetail.Category.CHART_CITYWISE)
        const cityCount = cityWiseData.map(x => x.y1);
        const cities = cityWiseData.map(x => x.x1);
        if(cityCount == null || cityCount == undefined || cityCount.length == 0) setIsEmpty(true)
        setSeriesData(cityCount);
        setChartTitle(DataConstant.ChartDetail.Title.CityWiseScanCount);
        setchartLabel(cities);
        break;
      default: break;
    }
  }

  return (
    <div id="chart">
      { !isEmpty ? (
        <>
          <ApexChart options={dataset.options} series={dataset.series} type={props.ChartType} height={props.Height} />
          {props.ShowDetail && (
            <QrChartDetail data={qrDetail} />  
          )}
        </>
        ) : (
          <>
          <p className="py-8 px-12 font-semibold">{chartTitle}</p>
          <Empty className="cm-web-preview-wrapper"/>
          </>
        )
      }
    </div>
  )
};

export default ChartComponent;
