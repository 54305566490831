import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FuseLoading from "@fuse/core/FuseLoading";
import URLConstant from "src/app/constants/urlConstant";
import _ from "lodash";
import axiosClient from "app/configs/axiosClientConfig";
import { Modal } from "antd";
import { Button } from "@mui/material";
import DataConstant from "src/app/constants/dataConstant";
import axios from "axios";

const QrCodeScanLog = () => {
    const [showModel, setShowModel] = useState(false);
    const { shortUrl } = useParams();
    const header = {
        'Authorization': 'Bearer d99giewVezqKfk81PWzifFQVQd9kSk5zyJp6KzHZR0rkmaXQcUmVc7oJy6qqVqjcoErMJ8pKYsiOCD5Sis5EcqWoWHiCKl8ZDorvfhxYCtjxuMsFbBtR1HiMS1XdumGzXUymboat00d2fgojr4cFAl88LscjpYmhvLS19oXcH9DI09iaKEUwfEAJVZK1l6UvAkuHmPViXOT7HAF8U1QxyJPR9iFawuD1J0hbaeiTALtytlmVj'
    };
    const addScanLogsInQrCode = (Latitude, Longitude, url, id) => {
        const model = {
            UrlCode: shortUrl,
            Latitude: Latitude,
            Longitude: Longitude,
            QRCodeId: id,
            UserAgent: window.navigator.userAgent,
            Device: getDeviceName()
        };
        //axiosClient
        axios
            .post(URLConstant.QRCodeScanLog.AddQRCodeScanLog, model, { headers: header })
            .then((response) => {                
                window.open(url, '_self');
            })
            .catch((error) => {
                if(error?.response?.status == 401 ){
                    alert("Unable to access");
                }
                console.log(error);
            });
    };
    const fetchData = () => {
        axiosClient
            .post(`${URLConstant.QRCode.GetQrCodeByShortHandUrl}/${shortUrl}`,URLConstant.OriginUrl)
            .then((response) => {
                if (response && response?.data) {
                    if (_.toLower(response?.status) == _.toLower(DataConstant.API_STATUS.SUCCESS)) {
                        let url = response?.data?.url;
                        if(response.data.type == DataConstant.qrcodeType.appStore){
                            const device = getDeviceName();
                            if(device == 'Android'){
                                url = response.data.playStoreLink || response.data.otherLink || response.data.appStoreLink;
                            }else if(device == 'iOS' || device == 'Mac OS'){
                                url = response.data.appStoreLink || response.data.otherLink || response.data.playStoreLink;
                            }else{
                                url = response.data.otherLink || response.data.appStoreLink || response.data.playStoreLink;
                            }
                        }
                        if (response?.data?.isGPSLocation === true) {
                            getLocation(url, response?.data?.id);
                        } else {
                            addScanLogsInQrCode(null, null, url, response?.data?.id);
                        }
                    } else {
                        setShowModel(true);
                    }
                } else {
                    setShowModel(true);
                }
            }).catch((error) => {
                console.log(error);
            });
    };
    const getLocation = async (url, id) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    addScanLogsInQrCode(position.coords.latitude, position.coords.longitude, url, id);
                },
                (error) => {
                    console.log(error);
                    addScanLogsInQrCode(null, null, url, id);
                }
            );
        } else {
            addScanLogsInQrCode(null, null, url, id);
        }
    }
    const getDeviceName = () => {
        if (window.navigator.userAgent) 
        {
            var userAgent = window.navigator.userAgent;
        
            // Check for Android
            if (userAgent.match(/Android/i)) {
                return 'Android';
            }
            // Check for Windows
            else if (userAgent.match(/Windows/i)) {
                return 'Windows';
            }
            // Check for Mac OS
            else if (userAgent.match(/Macintosh|Mac OS/i)) {
                return 'Mac OS';
            }
            // Check for iOS
            else if (userAgent.match(/iPhone|iPad|iPod/i)) {
                return 'iOS';
            }
            // Check for Linux
            else if (userAgent.match(/Linux/i)) {
                return 'Linux';
            }
            // Check for Windows Phone
            else if (userAgent.match(/Windows Phone/i)) {
                return 'Windows Phone';
            }
            // Check for BlackBerry
            else if (userAgent.match(/BlackBerry/i)) {
                return 'BlackBerry';
            }
            // Check for Kindle
            else if (userAgent.match(/Kindle|Silk/i)) {
                return 'Kindle';
            }
            // Check for Fire TV
            else if (userAgent.match(/FireTV/i)) {
                return 'FireTV';
            }
            // Check for PlayStation
            else if (userAgent.match(/PlayStation/i)) {
                return 'PlayStation';
            }
            // Check for Xbox
            else if (userAgent.match(/Xbox/i)) {
                return 'Xbox';
            }
            // Check for Nintendo
            else if (userAgent.match(/Nintendo/i)) {
                return 'Nintendo';
            }
            // If none of the above, print unknown device
            else {
                return 'Unknown';
            }
        }
        else {
            return '';
        }
    }

    useEffect(() => {
        if (shortUrl) {
            fetchData();
        }
    }, [shortUrl]);

    return (
        <>

            {showModel &&
                <div className="flex justify-center items-center h-screen font-bold">
                    <h1 className="text-center">The QR code is expired, Please contact the administrator.</h1>
                </div>
            }
          
                <FuseLoading />
            
        </>
    );
};

export default QrCodeScanLog;
