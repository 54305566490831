import { Paper, Typography, AccordionSummary, AccordionDetails, Accordion } from "@mui/material";
import React from "react";
import { Share } from '@mui/icons-material';

const SocialQRPreview = (props) => {
  var { data, isPreview } = props;

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  const videoId = getId(data?.YoutubeVideo);

  return (
    <>
      <div className="cm-phone-preview-wrapper">
        <img src={require('../../assets/img/iphone-frame.png')} alt="preview" className="cm-img-phone-preview img-fluid" />
        <div className="cm-preview-wrapper" style={{ backgroundImage: `url(${data?.BackGroundImage?.selectedImageUrl})` }}>
          { isPreview && <Typography className="cm-preview-ttl">Preview</Typography> }

          {data?.logo?.selectedImageUrl &&
            <Paper>
              <img src={data?.logo?.selectedImageUrl} alt="logo" className="logo" />
            </Paper>
          }
          <Paper>
            <Typography variant="subtitle1" component="h6" className="font-bold">{data?.Title}</Typography>
            <Typography variant="body1" component="p" className="mb-8">{data?.Description}</Typography>
          </Paper>

          {data?.YoutubeVideo && <Paper>
            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${videoId} `}
              allowFullScreen>
            </iframe>
          </Paper>
          }

          {data?.SocialLinks && data?.SocialLinks?.map((details, i) => {
            if (_.toLower(details.name) != "call" && _.toLower(details.name) != "mail" && !details.link.includes("https://")) {
              details.link = "https://" + details.link;
            }
            // if (_.toLower(details.name) == "call" && !details.link.includes("tel:")) {
            //   details.link = "tel:" + details.link;
            // }
            // if (_.toLower(details.name) == "mail" && !details.link.includes("mailto:")) {
            //   details.link = "mailto:" + details.link;
            // }
            return <>
              {_.toLower(details.name) == "call" &&
                <Paper key={i}>
                  <a className="cm-social-preview" href={"tel:" + details.link} >
                    <img src={details.imgSrc} alt="BusinessCardImage" className="BusinessCardImage" />
                    <div className="text-left">
                      <Typography variant="subtitle1" component="h6" className="font-bold">{details.name}</Typography>
                      <Typography variant="body1" component="p" className="mb-8">{details.title}</Typography>
                    </div>
                  </a>
                </Paper>
              }
              {_.toLower(details.name) == "mail" &&
                <Paper key={i}>
                  <a className="cm-social-preview" href={"mailto:" + details.link} >
                    <img src={details.imgSrc} alt="BusinessCardImage" className="BusinessCardImage" />
                    <div className="text-left">
                      <Typography variant="subtitle1" component="h6" className="font-bold">{details.name}</Typography>
                      <Typography variant="body1" component="p" className="mb-8">{details.title}</Typography>
                    </div>
                  </a>
                </Paper>
              }
              {_.toLower(details.name) != "call" && _.toLower(details.name) != "mail" &&
                <Paper key={i}>
                  <a className="cm-social-preview" href={details.link} >
                    <img src={details.imgSrc} alt="BusinessCardImage" className="BusinessCardImage" />
                    <div className="text-left">
                      <Typography variant="subtitle1" component="h6" className="font-bold">{details.name}</Typography>
                      <Typography variant="body1" component="p" className="mb-8">{details.title}</Typography>
                    </div>
                  </a>
                </Paper>
              }
            </>
          })}
          {data?.ShareThisPage === true &&
            <Accordion className="rounded-16" style={{ backgroundColor: data?.ButtonColor ? data?.ButtonColor : '#ffffff', color: data?.TextColor ? data?.TextColor : '#000000' }}>
              <AccordionSummary>
                <Share className="mr-8" />
                <Typography>Share this page</Typography>
              </AccordionSummary>
              <AccordionDetails className="flex items-center justify-center gap-8">
                <a href={data?.url && data.url != '' ? `https://www.facebook.com/sharer/sharer.php?u=${data.url}` : '#'}>
                  <img
                    className="max-w-30"
                    src={require('../../assets/img/facebook.png')}
                    alt="facebook"
                    name="facebook"
                  />
                </a>
                <a href={data.url && data.url != '' ? `https://www.twitter.com/share?url=${data.url}` : '#'}>
                  <img
                    className="max-w-30"
                    src={require('../../assets/img/twitter.jpg')}
                    alt="twitter"
                    name="twitter"
                  />
                </a>
              </AccordionDetails>
            </Accordion>
          }
          <Paper className="cm-link-wrapper">
            <a href={data?.AboutUs}>Link to about us</a>
            <a href={data?.Privacy}>Link to privacy policy</a>
          </Paper>
        </div>
      </div>
    </>
  );
}

export default SocialQRPreview;
