import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { MdOutlineAccountCircle } from "react-icons/md";
import {
  InvoicePackageInfo,
  companyProfileData,
  profilePic,
  selectUser,
} from "app/store/userSlice";
import { logout } from "src/app/auth/oidcConfig";
import { IoMdContact } from "react-icons/io";
import URLConstant from "../../constants/urlConstant";
import axiosClient from "app/configs/axiosClientConfig";
import moment from "moment";
import store from "app/store/index";
import DataConstant from "src/app/constants/dataConstant";
import FuseDialog from "@fuse/core/FuseDialog";
import SwitchRole from "src/app/main/UserProfile/SwitchRole";

function UserMenu(props) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

  const [userMenu, setUserMenu] = useState(null);
  const [openRoleModal, setOpenRoleModal] = useState(false);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleSignOut = () => {
    // authentication.signOut();
    logout();
  };

  useEffect(() => {
    if (loginDetail?.access_token) {
      fetchUser();
      getInvoiceActiveList(loginDetail?.profile?.userid);
    }
  }, [loginDetail?.access_token]);

  const fetchUser = async () => {
    const res = await axiosClient.get(URLConstant.user.getUserDetail);
    store.dispatch(
      profilePic({
        UserProfileImage: res?.picture,
      })
    );
    store.dispatch(
      companyProfileData({
        CompanyProfile: res?.logoPath,
      })
    );
  };

  const getInvoiceActiveList = async (id) => {
    axiosClient
      .get(`${URLConstant.Subscription.GetInvoiceActiveList}/${id}`)
      .then((response) => {
        if (response && response.data) {
          store.dispatch(
            InvoicePackageInfo({
              ExpiredDate: moment(response?.data?.expiredDate).format(
                DataConstant.dateFormats.YYYYMMDD
              ),
              Status: response?.data?.status,
              InvoiceType: response?.data?.invoiceType,
              CreatedDate: moment(response?.data?.invoiceDate).format(
                DataConstant.dateFormats.YYYYMMDD
              ),
              PackageStatus: response?.data?.packageStatus,
              RemainingDays: response?.data?.remainingDays,
            })
          );
        }
        else {
          store.dispatch(
            InvoicePackageInfo({
              ExpiredDate: "",
              Status: "InActive",
              InvoiceType: "",
              CreatedDate: "",
              PackageStatus: "Expired",
              RemainingDays: -1
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onMyaccountClick = () => {
    setUserMenu(null);
    navigate(`/manageprofile/${loginDetail?.profile?.userid}`);
  }

  const onCloseRoleModal = () => {
    setOpenRoleModal(false);
  };

  const onSwitchRoleClick = () => {
    //navigate("/switchrole")
    setOpenRoleModal(true);
  }

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {loginDetail?.profile?.name}
          </Typography>
          <Typography
            className="text-11 font-medium capitalize"
            color="text.secondary"
          >
            {loginDetail?.profile?.whitelabel} / {loginDetail?.profile?.UserRole}
          </Typography>
        </div>

        {user?.profilePic?.UserProfileImage &&
          user?.profilePic?.UserProfileImage != "" ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={
              URLConstant.Images.UserImageRootPath +
              user?.profilePic?.UserProfileImage
            }
          />
        ) : (
          <Avatar className="md:mx-4"></Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem onClick={onMyaccountClick} role="button" >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon className="text-48" size={24} color="action">heroicons-outline:user-circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="My account" />
            </MenuItem>
            <MenuItem onClick={onSwitchRoleClick} role="button" >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon className="text-48">heroicons-outline:switch-horizontal</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Switch role" />
            </MenuItem>
            <MenuItem onClick={handleSignOut} role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={Link}
              to="/apps/profile"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/apps/mailbox"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </MenuItem>
            <MenuItem
              component={NavLink}
              // to="/user"
              onClick={() => {
                userMenuClose();
              }}
            // onClick={() => logout()}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out " />
            </MenuItem>
          </>
        )}
      </Popover>

      {openRoleModal && (
        <SwitchRole
          show={openRoleModal}
          onHide={onCloseRoleModal}
        />
      )}
    </>
  );
}

export default UserMenu;
