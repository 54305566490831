import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import dataConstant from "src/app/constants/dataConstant";
import { useNavigate, useParams } from "react-router-dom";
import Schema from "async-validator";
import FuseLoading from "@fuse/core/FuseLoading";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import {
  Button,
  FormLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Paper,
} from "@mui/material";
import URLConstant from "src/app/constants/urlConstant";
import MessageConstant from "src/app/constants/messageConstant";
import PageHeader from "../../components/pageHeader";
import axiosClient from "../../configs/axiosClientConfig";

const ManageUser = () => {
  Schema.warning = function () { };
  const { id, subid } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  //const [userRole, SetUserRole] = useState("Admin");
  //const [isRoleDisable, SetIsRoleDisable] = useState(false);

  useEffect(() => {
    if (id) {
      getUserById(id, subid);
    } else
      setIsLoading(false);
  }, [id]);

  //Add Update User Data
  const onFinish = (userDetail) => {
    userDetail.id = id ? id : "";
    userDetail.subscriptionId = subid ? subid : "";
    const requestURL = id
      ? URLConstant.Employee.UpdateUser
      : URLConstant.Employee.CreateUser;
    axiosClient.post(requestURL, userDetail).then((response) => {
      if (response && response.status === dataConstant.API_STATUS.SUCCESS) {
        notification.success({
          message: dataConstant.Save,
          description: response.message,
        });
        redirectToUser();
      } else {
        notification.error({
          message: dataConstant.error,
          description: response.message,
        });
      }
    });
  };

  //get the user details
  const getUserById = (id, subid) => {
    axiosClient
      .get(`${URLConstant.Employee.GetuserId}/${id}/${subid}`, true)
      .then((response) => {
        if (response.data) {
          const { user, subscriptionUser } = response.data;
          //const { isMasterAdmin } = response.data;
          // SetIsRoleDisable(isMasterAdmin);
          setValue("UserName", user.userName);
          setValue("FirstName", subscriptionUser.firstName);
          setValue("LastName", subscriptionUser.lastName);
          //setValue("UserRole", user.userRole);
          setValue("PhoneNumber", subscriptionUser.phoneNumber);
          setValue("Email", user.email);
          // SetUserRole(user.userRole ?? "Admin");
          setIsLoading(false);
        }
      });
  };

  //Redirect to manageUser page for add user
  const redirectToUser = () => {
    navigate(`/user`);
  };

  if (IsLoading) { return <FuseLoading /> }

  return (
    <FusePageSimple
      header={
        <PageHeader title={id ? <h1 className="mr-16">Update user</h1> : <h1 className="mr-16">Add new user</h1>} showSearch={false} description="Dashboard" />
      }
      content={
        <Paper className="w-full">
          <form
            name="userForm"
            autoComplete="off"
            onSubmit={handleSubmit(onFinish)}
          >
            <input type="hidden" name="id" value={id} />

            <Grid container spacing={2} className="px-16 mt-0">
              <Grid item xs={12} sm={6}>
                <FormLabel className="mt-16 ">First name</FormLabel>
                <span className="text-[#ff0000] text-lg-start"> *</span>
                <TextField
                  {...register("FirstName", {
                    required: "First name is required",
                    maxLength:
                    {
                      value: 30,
                      message: 'Please enter maximum 30 character'
                    }
                  })}
                  fullWidth
                  autoComplete="off"
                  placeholder="Enter first name"
                  error={!!errors.FirstName}
                  helperText={errors.FirstName ? errors.FirstName.message : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel className="mt-16 ">Last name</FormLabel>
                <span className="text-[#ff0000] text-lg-start"> *</span>
                <TextField
                  {...register("LastName", {
                    required: "Last name is required",
                    maxLength:
                    {
                      value: 30,
                      message: 'Please enter maximum 30 character'
                    }
                  })}
                  fullWidth
                  autoComplete="off"
                  placeholder="Enter last name"
                  error={!!errors.LastName}
                  helperText={errors.LastName ? errors.LastName.message : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} hidden>
                <FormLabel className="mt-16 " hidden>User name</FormLabel>
                <span className="text-[#ff0000] text-lg-start"> *</span>
                <TextField
                  {...register("UserName", 
                  //{ required: true, maxLength: 25 }
                  )}
                  fullWidth
                  autoComplete="off"
                  placeholder="Enter a username"
                  error={!!errors.UserName}
                  helperText={errors.UserName ? "User name is required" : ""}
                  hidden
                />
              </Grid>
              {!id && (
                <>
                  <Grid item xs={12} sm={6} hidden>
                    <FormLabel className="mt-16 ">Initial password</FormLabel>
                    <TextField
                      {...register("Password", {
                        //required: true,
                        //pattern: dataConstant.passwordPattern,
                      })}
                      fullWidth
                      type="password"
                      autoComplete="new-password"
                      placeholder="Please enter a password"
                      error={!!errors.Password}
                      disabled
                      hidden
                      helperText={
                        errors.Password
                          ? MessageConstant.InvalidPasswordPattern
                          : ""
                      }
                      inputProps={{
                        onPaste: (e) => {
                          e.preventDefault();
                          return false;
                        },
                        onCopy: (e) => {
                          e.preventDefault();
                          return false;
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} hidden>
                    <FormLabel className="mt-16 ">Confirm password</FormLabel>
                    <span className="text-[#ff0000] text-lg-start"> *</span>
                    <TextField
                      {...register("ConfirmPassword", {
                        //required: true,
                        // validate: (value) =>
                        //   value === document.userForm.Password.value ||
                        //   "Confirm password does not match",
                      })}
                      fullWidth
                      type="password"
                      autoComplete="new-password"
                      placeholder="Enter confirm password"
                      error={!!errors.ConfirmPassword}
                      disabled
                      helperText={
                        errors.ConfirmPassword
                          ? errors.ConfirmPassword.message
                          : ""
                      }
                      inputProps={{
                        onPaste: (e) => {
                          e.preventDefault();
                          return false;
                        },
                        onCopy: (e) => {
                          e.preventDefault();
                          return false;
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6}>
                <FormLabel className="mt-16 ">Phone number</FormLabel>
                <span className="text-[#ff0000] text-lg-start"> *</span>
                <TextField
                  id="PhoneNumber"
                  name="PhoneNumber"
                  type="tel"
                  placeholder="Enter phone number"
                  onKeyDown={(e) => {
                    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
                    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  {...register("PhoneNumber", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^(?!(\d)\1+$)[\d+-]+$/,
                      message: "Please enter valid phone number",
                    },
                    minLength: {
                      value: 10,
                      message: "Please enter minimum 10 digits"
                    },
                    maxLength: {
                      value: 10,
                      message: "Please enter maximum 10 digits",
                    },
                  })}
                  error={!!errors.PhoneNumber}
                  helperText={
                    errors.PhoneNumber
                      ? errors.PhoneNumber.message || "Please enter valid phone number"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel className="mt-16 ">E-mail</FormLabel>
                <span className="text-[#ff0000] text-lg-start"> *</span>
                <TextField
                  {...register("Email", {
                    required: true,
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                  })}
                  fullWidth
                  type="email"
                  autoComplete="off"
                  name="Email"
                  placeholder="Please enter your email address"
                  error={!!errors.Email}
                  helperText={
                    errors.Email
                      ? "Email address is required and must be valid"
                      : ""
                  }
                  inputProps={{ maxLength: 150 }}
                  disabled={id}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <FormLabel id="demo-radio-buttons-group-label">
                  User role
                </FormLabel>
                <span className="text-[#ff0000] text-lg-start"> *</span>
                <RadioGroup
                  key={userRole}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={userRole ?? "Admin"}
                  name="UserRole"
                  className="flex-row"

                >
                  {isRoleDisable ?
                    <>
                      <FormControlLabel
                        name="UserRole"
                        value="Admin"
                        control={<Radio />}
                        label="Admin"                        
                        disabled={isRoleDisable}

                      />
                      <FormControlLabel
                        name="UserRole"
                        value="User"
                        control={<Radio />}
                        label="User"                        
                        disabled={isRoleDisable}
                      />
                    </>
                    :
                    <>
                      <FormControlLabel
                        name="UserRole"
                        value="Admin"
                        control={<Radio />}
                        label="Admin"
                        {...register("UserRole", { required: "Phone number is required" })}

                      />
                      <FormControlLabel
                        name="UserRole"
                        value="User"
                        control={<Radio />}
                        label="User"
                        {...register("UserRole", { required: "Phone number is required" })}
                      />
                    </>
                  }

                </RadioGroup>
              </Grid> */}
              <Grid item xs={12}>
                <div className="flex items-center gap-10 justify-end">
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className="my-8 flex"
                    onClick={redirectToUser}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="my-8 flex"
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      }
    />
  );
};
export default ManageUser;
