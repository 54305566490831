import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabList, TabPanel } from "@mui/lab";
import Url from "./UrlPage";
import FuseLoading from "@fuse/core/FuseLoading";
import PDF from "./PDFPage";
import TabContext from "@mui/lab/TabContext";
import URLConstant from "src/app/constants/urlConstant";
import axiosClient from "../../configs/axiosClientConfig";
import SocialQR from "./SocialQR";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import PageHeader from "../../components/pageHeader";
import DataConstant from "src/app/constants/dataConstant";
import WifiPage from "./WifiPage";
import InstagramPage from "./InstagramPage";
import WhatsappPage from "./WhatsappPage";
import AppStorePage from "./AppStorePage";

const ManageQRCode = () => {
  const { id } = useParams();
  const [value, setValue] = React.useState("0");
  const [data, SetData] = React.useState("");
  const [IsLoading, setIsLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getQRCodeById = () => {
    axiosClient
      .get(`${URLConstant.QRCode.GetQRCodeById}?QRCodeId=${id}`)
      .then((response) => {
        if (response.data) {
          SetData(response.data);
          if (id && response.data.type === DataConstant.qrcodeType.pdf) {
            setValue("1"); // Set value to "1" for PDF tab
          } else if (
            id &&
            response.data.type === DataConstant.qrcodeType.landingPageQR
          ) {
            setValue("2");
          }else if (
            id &&
            response.data.type === DataConstant.qrcodeType.whatsapp
          ) {
            setValue("3");
          }else if (
            id &&
            response.data.type === DataConstant.qrcodeType.wifi
          ) {
            setValue("4");
          }
          else if (
            id &&
            response.data.type === DataConstant.qrcodeType.instagram
          ) {
            setValue("5");
          } else if (
            id &&
            response.data.type === DataConstant.qrcodeType.appStore
          ) {
            setValue("6");
          }
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getQRCodeById();
    } else setIsLoading(false);
  }, [id]);

  if (IsLoading) {
    return <FuseLoading />;
  }

  return (
    <FusePageSimple
      header={
        <PageHeader
          title={
            id ? (
              <h1 className="mr-16">Update QR code</h1>
            ) : (
              <h1 className="mr-16">Add QR code</h1>
            )
          }
          showSearch={false}
          description="Setting"
        />
      }
      content={
        <TabContext value={value}>
          <Grid container className="px-12 cm-socialQR-wrapper" spacing={2}>
            <Grid item lg={3} xs={12}>
              <Paper className="py-12">
                <TabList
                  orientation="vertical"
                  variant="scrollable"
                  // value={value}
                  onChange={handleChange}
                >
                  <Tab
                    value="0"
                    label="URL"
                    disabled={
                      id && data && data.type !== DataConstant.qrcodeType.url
                    }
                  />
                  <Tab
                    value="1"
                    label="PDF"
                    disabled={
                      id && data && data.type !== DataConstant.qrcodeType.pdf
                    }
                  />
                  <Tab
                    value="2"
                    label="Landing page QR"
                    disabled={
                      id &&
                      data &&
                      data.type !== DataConstant.qrcodeType.landingPageQR
                    }
                  />
                  <Tab
                    value="3"
                    label="WhatsApp"
                    disabled={id && data && data.type !== DataConstant.qrcodeType.whatsapp}
                  />
                  <Tab
                    value="4"
                    label="WIFI"
                    disabled={
                      id && data && data.type !== DataConstant.qrcodeType.wifi
                    }
                  />
                  <Tab
                    value="5"
                    label="Instagram"
                    disabled={
                      id && data && data.type !== DataConstant.qrcodeType.instagram
                    }
                  />
                  <Tab
                    value="6"
                    label="App Store"
                    disabled={id && data && data.type !== DataConstant.qrcodeType.appStore}
                  />
                </TabList>
              </Paper>
            </Grid>
            <Grid item lg={9} xs={12}>
              <Paper className="h-full">
                <TabPanel value="0">
                  <Url data={data} />
                </TabPanel>
                <TabPanel value="1">
                  <PDF data={data} />
                </TabPanel>
                <TabPanel value="2">
                  <SocialQR data={data} />
                </TabPanel>
                < TabPanel value="3" >
                  <WhatsappPage data={data} />
                </TabPanel >
                <TabPanel value="4">
                  <WifiPage data={data} />
                </TabPanel>
                <TabPanel value="5">
                  <InstagramPage data={data} />
                </TabPanel>
                < TabPanel value="6" >
                  <AppStorePage data={data} />
                </TabPanel >
              </Paper>
            </Grid>
          </Grid>
        </TabContext>
      }
    />
  );
};

export default ManageQRCode;
