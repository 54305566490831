const { REACT_APP_API_URL } = process.env;

const DataConstant = {
  REACT_APP_API_URL,
  API_STATUS: {
    SUCCESS: "Success",
    FAILED: "FAILED",
    EMPTY: "EMPTY",
    WARNING: "Warning",
  },
  NetworkTypes: [
    {display:"None",value: "nopass"},
    {display:"WEP",value: "WEP"},
    {display:"WPA/WPA2",value: "WPA"},
  ],
  tableRequest: {
    pageNumber: 1,
    rowsPerPage: 10,
    SearchText: "",
    searchColumns: [],
    sortColumns: {
      sortColumnName: "createdDate",
      sortOrder: "desc",
    },
  },
  User: "User added",
  UserUpdated: "User updated ",
  SWW: "Something went wrong",
  Add: "Add",
  Update: "Update",
  Edit: "Edit",
  error: "Failed",
  Delete: "Delete",
  Refresh: "Refresh",
  Detail: "Detail",
  Clone: "Clone",
  Save: " Saved",
  Warning: "Warning",
  Cancel: "Cancel subscription",
  Cancelled: "Cancelled",
  Active: "Active",
  InActive: "InActive",
  Expired: "Expired",
  SaveTemplate: "Css template save successfully",
  Required: "Required",
  titleRequired: "Title is required",
  youtubeRequired: "Youtube video link is required",
  aboutUsRequired: "About us link is required",
  privacyPolicyRequired: "Privacy policy link is required",
  aboutUsLinkInvaliMessage: "Please provide valid about us url",
  privacyLinkInvaliMessage: "Please provide valid privacy policy url",
  urlRegex:/^(ftp|http|www)[^\s]+$/,
  invalidUrlMessage: "Please provide valid url",
  invalidUrlCode: "Please provide valid url code",
  urlCodeRegex:/^[A-Za-z0-9]{1,24}$/,
  urlCodeMessage:"Please enter a valid code using only letters and numbers (maximum 24 characters). Example: Abc123",
  whitelabelInfo:"We use subdomains to increase your company branding while your customers are using the qr code.  The subdomain is in the website url.  An example is yourcompany.qrrelays.com. yourcompany is the subdomain in this example. Your preferred subdomain should be a word that gives your customers confidence that the QR is taking them to the correct company.",
  whitelabelRegex: /^[a-zA-Z0-9-_]{1,75}$/,
  pagination: {
    pageNumber: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["50", "100", "250"],
  },
  passwordPattern:/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  dateFormats: {
    YYYYMMDD: "YYYY-MM-DD",
  },
  packageType: {
    Free: "Free",
    Quarterly: "Quarterly",
    Year: "Year",
  },
  userRole: {
    Admin: "Admin",
    User: "User",
  },
  imageFileSize: "5242880", //5 MB
  pdfFileSize: "10485760", // 10 MB
  imagePattern: /\.(jpg|jpeg|png)$/,

  qrcodeType: {
    landingPageQR: "Landing page QR",
    url: "Url",
    pdf: "PDF",
    wifi: "Wifi",
    instagram :"Instagram",
    whatsapp: "Whatsapp",
    appStore: "App Store"
  },
  PlanDetail: {
    MonthLengthInYearly: 12,
    MonthLengthInQuarterly: 3,
    YearlyBilling: "Yearly billing",
    QuaterlyBilling: "Quaterly billing",
    BilledQuaterly: "billed quaterly ",
    BilledYearly: "billed yearly",
    Year: "Year",
    Qauter: "Quarterly",
    YearlyPlanName: "Join Premium Yearly",
    QauterlyPlanName: "Join Premium Quarterly",
  },
  ChartDetail: {
    Type: {
      Line: "line",
      Area: "area",
      Bar: "bar",
      Pie: "pie",
      Donut: "donut",
    },
    Category: {
      CHART_DATEWISE: "CHART_DATEWISE",
      CHART_DEVICEWISE: "CHART_DEVICEWISE",
      CHART_TYPEWISETOP5: "CHART_TYPEWISETOP5",
      CHART_TAGWISE: "CHART_TAGWISE",
      CHART_TYPEWISE: "CHART_TYPEWISE",
      CHART_TOP10QRCODE: "CHART_TOP10QRCODE",
      CHART_CITYWISE: "CHART_CITYWISE",
    },
    Title: {
      DateWiseScanCount: "Date wise scan count",
      DeviceWiseScanCount: "Device wise scan count",
      TypeWiseTop5QrcodeScanCount: "Top 5 QR code scan count by type",
      TagWiseScanCount: "Tag wise scan count",
      TypeWiseTotalScanCount: "Type wise total scan count",
      Top10QrCodeScanCount: "Top 10 QR code by scan count",
      CityWiseScanCount: "City wise scan count",
    },
    Height: 350,
  },
};

export default DataConstant;
