import config from "../configs/config";

const { apiURL } = config;
const { IdentityClientID, OriginUrl } = config;

const URLConstant = {
  user: {
    getUserDetail: `${apiURL}/api/me`,
  },
  Images: {
    UserImageRootPath: `${apiURL}/Resources/Users/`,
    UserDefaultImagePath: `${apiURL}/Resources/Users/profile-picture.png`,
    UserSocialRootPath: `${apiURL}/Resources/Social/`,
    UserCompanyLogoPath: `${apiURL}/Resources/Users/company/`,
  },
  Employee: {
    GetUsersList: `${apiURL}/api/Employees/getallemployee`,
    CreateUser: `${apiURL}/api/employees/QuickAddUser`,
    GetuserId: `${apiURL}/api/employees/getemployee`,
    UpdateUser: `${apiURL}/api/Employees/updateEmployee`,
    DeleteUser: `${apiURL}/api/employees/delete`,
    ChangeImage: `${apiURL}/api/employees/changeprofilepic`,
    SendInvitation: `${apiURL}/api/employees/sendinvitation`,
    GetUserRoles: `${apiURL}/api/employees/getuserroles`,
    GetUserLastLogin: `${apiURL}/api/employees/getuserlastlogin`,
    AddUpdateUserLastLogin: `${apiURL}/api/employees/addupdateuserlastlogin`,
    CreateUserAccount:`${apiURL}/api/employees/createuseraccount`,
  },
  QRCode: {
    GetQRCodeList: `${apiURL}/api/QRCodes/GetQRCodes`,
    GetShortHandUrl: `${apiURL}/api/QRCodes/getShortHandUrl`,
    AddQRCodes: `${apiURL}/api/QRCodes/AddQRCodes`,
    AddPdfFileQRCode: `${apiURL}/api/QRCodes/AddPdfFileQRCode`,
    GetQRCodeById: `${apiURL}/api/QRCodes/getqrcode`,
    UpdateUrlQRCode: `${apiURL}/api/QRCodes/updateQRCodes`,
    UpdateGpsLocation: `${apiURL}/api/QRCodes/updateGPSLocation`,
    UpdatePdfFileQRCode: `${apiURL}/api/QRCodes/updatePdfFileQRCode`,
    DeleteQRCode: `${apiURL}/api/QRCodes/delete`,
    DownloadURLQRCode: `${apiURL}/api/QRCodes/downloadurlqrcode`,
    UpdateMailFlag: `${apiURL}/api/QRCodes/addUpdateEmail`,
    AddUpdateTag: `${apiURL}/api/QRCodes/addUpdateTags`,
    GetTagsByIdAsync: `${apiURL}/api/QRCodes/getTagsByIdAsync`,
    AddUpdateSocialQRCode: `${apiURL}/api/QRCodes/AddUpdateSocialQRCode`,
    GetQrCodeByShortHandUrl: `${apiURL}/api/QRCodes/GetQrCodeByShortHandUrl`,
  },
  QRCodeScanLog: {
    AddQRCodeScanLog: `${apiURL}/api/QRCodeScanLogs/addQRCodeScanLogs`,
    GetQRCodeScanLog: `${apiURL}/api/QRCodeScanLogs/GetQRCodesScanLogs`,
    ResetQRCodeScanLog: `${apiURL}/api/QRCodeScanLogs/resetQRCodeScan`,
  },
  CssTemplates: {
    GetCssTemplateList: `${apiURL}/api/CssTemplates/GetCssTemplates`,
    CreateCssTemplate: `${apiURL}/api/CssTemplates/AddCssTemplate`,
    GetCssById: `${apiURL}/api/CssTemplates/getcsstemplate`,
    UpdateCssTemplate: `${apiURL}/api/CssTemplates/UpdateCssTemplate`,
    DeleteCssTemplate: `${apiURL}/api/CssTemplates/delete`,
    GetCssTemplateDropDownList:`${apiURL}/api/CssTemplates/getCssTemplateDropDownList`
  },
  CompanyProfile: {
    getplandetail: `${apiURL}/api/settings/CompanyProfile/getplandetail`,
    ChangeLogo: `${apiURL}/api/settings/CompanyProfile/ChangeLogo`,
    ChangeCompanyTineZone: `${apiURL}/api/settings/CompanyProfile/ChangeCompanyTineZone`,
  },
  Orders: {
    GetOrdersList: `${apiURL}/api/Orders/GetOrders`,
    GetOrderById: `${apiURL}/api/Orders/getOrders`,
  },
  UserPaymentProfile: {
    GetUserPaymentProfileList: `${apiURL}/api/UserPaymentProfile/GetUserPayment`,
    GetUserActivePaymentProfileList: `${apiURL}/api/UserPaymentProfile/GetUserActivePaymentDetails`,
    CreateUserPaymentProfile: `${apiURL}/api/UserPaymentProfile/CreateUserPaymentProfile`,
    DeleteUserPaymentProfile: `${apiURL}/api/UserPaymentProfile/delete`,
    SetDefaultPaymentProfile: `${apiURL}/api/UserPaymentProfile/SetDefaultPaymentProfile`,
  },
  Subscription: {
    GetSubscriptionItemList: `${apiURL}/api/Subscription/GetSubscription`,
    CreateUserSubscription: `${apiURL}/api/Subscription/CreateUserSubscription`,
    CreateUserSubscriptionWithCard: `${apiURL}/api/Subscription/CreateUserSubscriptionWithCard`,
    CancelUserSubscription: `${apiURL}/api/Subscription/CancelUserSubscription`,
    GetInvoiceList: `${apiURL}/api/Invoices/GetInvoices`,
    GetInvoiceActiveList: `${apiURL}/api/Invoices/getInvoiceActiveList`,
    CreateUserPackage: `${apiURL}/api/Subscription/CreateUserPackage`,
  },
  Dashboard: {   
    GetDashboardStaticData: `${apiURL}/api/QRCodes/GetDashboardStaticData`,
    GetDashboardChartData: `${apiURL}/api/QRCodes/GetDashboardChartData`,
  },
  OriginUrl: OriginUrl,
  HostName: IdentityClientID,
};
export default URLConstant;
