const MessageConstant = {
  Required: "Please enter",
  SelectRequired: "Please select",
  PASSWORD_REQUIRED: "Password is required.",
  DeleteConfirmation: "Delete confirmation",
  CancelConfirmation: "Cancel confirmation",
  DeleteConfirm: "Are you sure you want to delete ?",
  CancelConfirm: "Are you sure you want to cancel subscription?",
  InvalidPasswordPattern:
    "Password must contain one uppercase, at least 8 characters, one lowercase, one special character,",
  PasswordDoseNotMatch: "New password and confirm password must be same.",
  EnterValid: "Please enter valid",
  TryAgain : "Please try again later",
  okText: "Yes",
  cancleText: "No",
  delete: "Delete successfully",
  AlreadyExists : "Card number already exists",
  Add: "User added successfully",
  UrlAdd: "URL added successfully",
  CardAdded : "Card added successfully",
  Update: "User updated successfully",
  FieldIsNotMoreThan25Char: "This field should not be more than 25 characters",
  PaymentDone : "Payment successful!",
  Done : "Done",
  PaymentCancel : "Payment cancel successfully!",
  ImageValidationSize: "File size should be less than 5 MB.",
  ImageValidationType: "Please select only jpg, jpeg, png files.",
  PdfValidationType: "Please select only pdf files.",
  PdfValidationSize: "PDF file size should be less than 10 MB.",
  SubdomainRequired: "Preferred subdomain is required",
  EnterValidSubdomain: "Please enter a valid subdomain using only letters, numbers, hyphens, and underscores (maximum 75 characters)",
  FirstNameRequired: "First name is required",
  LastNameRequired: "Last name is required",
  EnterMaximum30Char: "Please enter maximum 30 character",
  PhonenumberRequired: "Phone number is required",
  EnterValidPhonenumber: "Please enter valid phone number",
  EnterMinimum10: "Please enter minimum 10 digits",
  EnterMaximum10: "Please enter maximum 10 digits",
  CodeRestricted: "You can't use this code, this code is restricted.",
  EnterCode: "Please enter code",
  EnterMaximum60Char: "Please enter maximum 60 characters",
  NameRequired: "Name is required",
  UrlRequired: "Url is required",
  TitleMaximum60Char: "Title should be maximum 60 characters",
  UploadPdfOrPasteUrl: "Please upload PDF or paste URL",
  UrlIncludesInstagram:"Please enter valid instagram post url",
  EnterValidAppStoreURL: "Please enter a valid App Store Link",
  EnterValidPlayStoreURL: "Please enter a valid Play Store Link",
  EnterValidURL: "Please enter a valid Link",
  CountryCodeRequired: "Country code is required",
  EnterwhatsappNumber: "Please enter a valid phone number",
  EnterValidCountryCode: "Please enter a valid country code",
  LinkValidation: "Please provide atleast one link (App Store, Play Store or Other Device link) to generate a QR code.",
  EnterValidURL:"Please enter a valid Link"
};
export default MessageConstant;
