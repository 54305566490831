import _, { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import URLConstant from "src/app/constants/urlConstant";
import GridComponent from "src/app/components/gridComponent";
import useDebounce from "src/@fuse/hooks/useDebounce";
import DownloadModel from "./DownloadModel";
import { Modal, notification } from "antd";
import dataConstant from "src/app/constants/dataConstant";
import messageConstant from "src/app/constants/messageConstant";
import axiosClient from "../../configs/axiosClientConfig";
import FuseLoading from "@fuse/core/FuseLoading";
import {
  Tooltip,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Switch,
  Button,
  Chip,
  IconButton,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import MailPopup from "./MailPopup";
import ShareQRCode from "./ShareQRCode";
import TagPopup from "./TagPopup";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import PageHeader from "../../components/pageHeader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import DataConstant from "src/app/constants/dataConstant";
import moment from "moment";

const QRCode = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [isExpireModalShow, setIsExpireModalShow] = useState(false);

  const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

  useEffect(() => {
    if (user) {
      var packageStartDate = user?.InvoiceInfo?.ExpiredDate;
      const isPackageExpired = () => {
        if (!packageStartDate) {
          return true;
        }
        return user?.InvoiceInfo?.PackageStatus === DataConstant.Expired;
        //const currentDate = new Date();
        //return moment(currentDate).format(DataConstant.dateFormats.YYYYMMDD) > moment(new Date(packageStartDate)).format(DataConstant.dateFormats.YYYYMMDD);
      };
      const packageExpired = isPackageExpired();
      setIsExpireModalShow(packageExpired);
    }
  }, [user?.InvoiceInfo])


  const [tableRequestParam, setTableRequestParam] = useState(
    JSON.parse(JSON.stringify(dataConstant.tableRequest))
  );
  const [qrCodeList, setQrCodeList] = useState({
    count: 0,
    data: [],
  });
  const [pageNumber, setPageNumber] = useState(DataConstant.tableRequest.pageNumber);
  const [refreshTable, setRefreshTable] = useState(Math.random());
  const debouncedSearch = useDebounce(searchText, 1000);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);

  const [location, setLocation] = useState({
    Latitude: null,
    Longitude: null,
  });
  const [IsLoading, setIsLoading] = useState(true);
  const [urlForShare, setUrlForShare] = useState();
  const [isShow, invokeModal] = useState(false);
  const [showMailPopup, setShowMailPopup] = useState(false);
  const [qrCodeId, setQRCodeId] = useState("");
  const [isMail, setIsMail] = useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);

  const handleCancel = () => {
    invokeModal(false);
  };
  const handleMailPopupCancel = () => {
    setShowMailPopup(false);
  };

  const handleTagPopupCancel = () => {
    setOpenTagModal(false);
  };

  const openMailPopup = (event, id) => {
    setIsMail(event.target.checked);
    setQRCodeId(id);
    if (event.target.checked) {
      setShowMailPopup(true);
    } else {
      updateEmailFlag(id, event.target.checked);
    }
  };

  const updateEmailFlag = (id, ismail) => {
    const model = {
      Id: id,
      IsMail: ismail,
    };
    axiosClient
      .post(URLConstant.QRCode.UpdateMailFlag, JSON.stringify(model))
      .then((response) => {
        fetchData(tableRequestParam);
        notification.success({
          message: dataConstant.Save,
          description: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Refresh = () => {
    setSearchText("");
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "code",
      sorter: false,
      width: 100,
      dataType: "StringContains",
      //hidden: loginDetail?.profile?.UserRole?.toLowerCase() === DataConstant.userRole.User.toLowerCase(),
      render: (render) => (
        <span>
          <Tooltip title={dataConstant.Edit}>
            <IconButton onClick={() => EditQRCode(render.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={dataConstant.Delete}>
            <IconButton onClick={() => confirmDeleteQRCode([render.id])}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      title: "Image",
      dataIndex: "qrCodeImage",
      key: "qrCodeImage",
      sorter: false,
      width: 150,
      dataType: "StringContains",
      render: (image) => <img className="max-w-64 mx-auto" src={image} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 150,
      dataType: "StringContains",
    },
    {
      title: "Scan Count",
      dataIndex: "",
      key: "qrCodeScanCount",
      sorter: true,
      width: 100,
      dataType: "StringContains",
      //hidden: loginDetail?.profile?.UserRole?.toLowerCase() === DataConstant.userRole.User.toLowerCase(),
      render: (render) => (
        <Link
          component="button"
          variant="Link"
          underline="none"
          className="w-full my-8"
          onClick={() => onCountClick(render.id)}
        >
          {render.qrCodeScanCount}
        </Link>
      ),
    },
    {
      title: "Options",
      dataIndex: "",
      key: "Options",
      sorter: false,
      width: 200,
      dataType: "StringContains",
      render: (data) => (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-semibold" >Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          heroicons-outline:download
                        </FuseSvgIcon>
                      }
                      onClick={() => {
                        onDownloadClick(data.id, data.name);
                      }}
                    >
                      Download
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          heroicons-outline:share
                        </FuseSvgIcon>
                      }
                      onClick={() => {
                        openModalForShare(data.url);
                      }}
                    >
                      Share
                    </Button>
                  </div>
                </Grid>
                {/* <Grid item xs={4}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          heroicons-outline:location-marker
                        </FuseSvgIcon>
                      }
                    >
                      Edit location
                    </Button>
                  </div>
                </Grid> */}
                {/* <Grid item xs={4}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:password
                        </FuseSvgIcon>
                      }
                    >
                      Password
                    </Button>
                  </div>
                </Grid> */}
                {/* <Grid item xs={4}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:qr_code
                        </FuseSvgIcon>
                      }
                    >
                      Design QR
                    </Button>
                  </div>
                </Grid> */}
                {/* <Grid item xs={4}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:schedule
                        </FuseSvgIcon>
                      }
                    >
                      Scheduler
                    </Button>
                  </div>
                </Grid> */}
                {/* <Grid item xs={4}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:bar_chart
                        </FuseSvgIcon>
                      }                 
                    >
                      Statistics
                    </Button>
                  </div>
                </Grid> */}
                <Grid item xs={12}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:bar_chart
                        </FuseSvgIcon>
                      }
                      onClick={() => {
                        onTagClick(data.id);
                      }}
                    >
                      Tags
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:refresh
                        </FuseSvgIcon>
                      }
                      onClick={() => {
                        confirmResetTrackData(data.id, data.name);
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </Grid>
                {/* <Grid item xs={4}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:snippet_folder
                        </FuseSvgIcon>
                      }
                    >
                      URL monitor is on
                    </Button>
                  </div>
                </Grid> 
                <Grid item xs={4}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:email
                        </FuseSvgIcon>
                      }
                    >
                      {data.isMail ? "Mail is on" : "Mail is off"}
                      <Switch
                        checked={data.isMail ? data.isMail : false}
                        onChange={() => openMailPopup(event, data.id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Button>
                  </div>
                </Grid>*/}
                <Grid item xs={12}>
                  <div className="flex">
                    <Button
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          material-outline:location_on
                        </FuseSvgIcon>
                      }
                    >
                      {/* {data.isGPSLocation ? "Gps is on" : "Gps is off"} */}
                      {"GPS"}
                      <Switch
                        checked={
                          data.isGPSLocation ? data.isGPSLocation : false
                        }
                        onChange={() => handleGpsChange(event, data)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      ),
    },
    {
      title: "URL",
      dataIndex: "",
      key: "url",
      sorter: false,
      width: 200,
      dataType: "StringContains",
      render: (data) =>
        data.type === "Wifi" ? ("") : (data.url),
    },
    {
      title: "Short hand URL",
      dataIndex: "",
      key: "code",
      sorter: false,
      width: 100,
      dataType: "StringContains",
      render: (render) =>
        render.subTypes === "Static" ? (
          ""
        ) : (
          <Link
            component="button"
            variant="Link"
            underline="none"
            onClick={() =>
              window.open(URLConstant.OriginUrl + "/" + render.code, "_blank")
            }
          >
            {URLConstant.OriginUrl + "/" + render.code}
          </Link>
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
      width: 100,
      dataType: "StringContains",
      render: (type, _) => (
        <>
          <Chip label={type} color="primary" size="small" />
        </>
      ),
    },
    {
      title: "Subtype",
      dataIndex: "subTypes",
      key: "subTypes",
      sorter: true,
      width: 120,
      dataType: "StringContains",
    },
  ].filter((item) => !item.hidden);;

  const onTagClick = (Id) => {
    setOpenTagModal(true);
    setQRCodeId(Id);
  };

  const onDownloadClick = (id, name) => {
    setSelectedId(id);
    setSelectedName(name);
    setOpenModal(true);
  };
  const closeModel = () => {
    setOpenModal(false);
  };

  const confirmDeleteQRCode = (Id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: messageConstant.okText,
      cancelText: messageConstant.cancleText,
      onOk: () => {
        deleteQRCode(Id);
      },
    });
  };

  const deleteQRCode = (Id) => {
    axiosClient.post(URLConstant.QRCode.DeleteQRCode, Id).then((response) => {
      if (response) {
        notification.success({
          message: dataConstant.Delete,
          description: messageConstant.delete,
        });
        getRequestObj(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
      } else if (response.status === dataConstant.API_STATUS.FAILED) {
        notification.error({
          message: dataConstant.Delete,
          description: messageConstant.error,
        });
      }
    });
  };
  const handleGpsChange = (event, data) => {
    const model = {
      Id: data.id,
      IsGPSLocation: event.target.checked,
    };
    if (model.IsGPSLocation) {
      //getLocation();
    }
    axiosClient
      .post(URLConstant.QRCode.UpdateGpsLocation, model)
      .then((response) => {
        fetchData(tableRequestParam);
        notification.success({
          message: dataConstant.Save,
          description: response.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const EditQRCode = (id) => {
    navigate(`/manageqrcode/${id}`);
  };

  const onCountClick = (id) => {
    navigate(`/qrcodescanlog/${id}`);
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          Latitude: position.coords.latitude,
          Longitude: position.coords.longitude,
        });
      });
    }
  }

  const ShortHandUrlClick = (data) => {
    // if (data.isGPSLocation) {
    //   getLocation();
    // }

    const newWindow = window.open(data.url, "_blank");

    if (data.isGPSLocation) {
      getLocation();
    }

    const model = {
      UrlCode: data.code,
    };

    setTimeout(() => {
      if (data.isGPSLocation) {
        model.Latitude = location.Latitude;
        model.Longitude = location.Longitude;
      }
      axiosClient
        .post(URLConstant.QRCodeScanLog.AddQRCodeScanLog, model)
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    }, 2000);
  };

  const fetchData = (requestObject) => {
    axiosClient
      .post(URLConstant.QRCode.GetQRCodeList, requestObject)
      .then((response) => {
        setQrCodeList({
          data: response.data.data.list,
          count: response.data.data.totalCount,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRequestObj = (requestObject, pagenumber = 1) => {
    setPageNumber(pagenumber);
    if (requestObject && !isEqual(requestObject, tableRequestParam)) {
      setTableRequestParam(requestObject);
      if (
        isEqual(
          requestObject,
          JSON.parse(JSON.stringify(DataConstant.tableRequest))
        )
      ) {
        setRefreshTable(Math.random());
      }
      setSearchText(requestObject.searchText);
    } else if (
      requestObject &&
      isEqual(
        requestObject,
        JSON.parse(JSON.stringify(DataConstant.tableRequest))
      )
    ) {
      setRefreshTable(Math.random());
      fetchData(requestObject);
    } else {
      setRefreshTable(Math.random());
      fetchData(tableRequestParam);
    }
  };

  useEffect(() => {
    if (tableRequestParam)
      fetchData(tableRequestParam);
  }, [JSON.stringify(tableRequestParam)]);

  useEffect(() => {
    if (debouncedSearch != undefined) {
      setTableRequestParam({
        ...tableRequestParam,
        SearchText: debouncedSearch,
        pageNumber: 1,
      });
      setPageNumber(1);
    }
  }, [debouncedSearch]);

  const redirectToAddQRCode = () => {
    navigate(`/manageqrcode`);
  };

  const resetTrackData = (id) => {
    axiosClient
      .delete(`${URLConstant.QRCodeScanLog.ResetQRCodeScanLog}/${id}`)
      .then((response) => {
        if (response) {
          notification.success({
            message: dataConstant.Save,
            description: response.data,
          });
          fetchData(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
        } else if (response.status === dataConstant.API_STATUS.FAILED) {
          notification.error({
            message: dataConstant.User,
            description: messageConstant.error,
          });
        }
      });
  };

  const confirmResetTrackData = (id, name) => {
    Modal.confirm({
      title: "Are you sure?",
      content: `Delete scan count data of "${name}" ?`,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        resetTrackData(id);
      },
    });
  };

  const openModalForShare = (url) => {
    invokeModal(true);
    setUrlForShare(url);
  };

  if (IsLoading) {
    return <FuseLoading />;
  }

  if (isExpireModalShow) {
    return (
      <div className="px-16">
        <div>
          <Modal open={true} onCancel={() => navigate("/dashboard")} maskClosable={false} footer={null}>
            <div className="ant-modal-header">
              <div className="ant-modal-title">Package expired!</div>
            </div>
            <div className="ant-modal-body">Please upgrade your package to access this feature</div>
            <div className="ant-modal-footer">
              { loginDetail?.profile?.UserRole?.toLowerCase() === DataConstant.userRole.Admin.toLowerCase() &&
                <Button
                  className=" "
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/UpgradePackage")}>
                  Upgrade Package
                </Button>
              }
            </div>
          </Modal>
        </div>
        <TagPopup
          openTagModal={openTagModal}
          handleTagPopupCancel={handleTagPopupCancel}
          qrCodeId={qrCodeId}
          fetchData={fetchData}
          tableRequestParam={tableRequestParam}
        />
        <MailPopup
          isShow={showMailPopup}
          handleMailPopupCancel={handleMailPopupCancel}
          isMail={isMail}
          qrCodeId={qrCodeId}
          fetchData={fetchData}
          tableRequestParam={tableRequestParam}
        />
        <ShareQRCode
          urlForShare={urlForShare}
          isShow={isShow}
          handleCancel={handleCancel}
        />

        <PageHeader
          title="QR code"
          showSearch
          showAddButton
          onClick={redirectToAddQRCode}
          description="QrPage"
          searchText={setSearchText}
        />
        <GridComponent
          key={refreshTable}
          rowKey="id"
          columns={columns}
          dataSource={qrCodeList.data}
          getGridData={getRequestObj}
          tableRequest={JSON.parse(JSON.stringify(tableRequestParam))}
          totalCount={qrCodeList.count}
          scroll={{ x: "max-content", y: "40vh" }}
          refresh={Refresh}
          SearchText={searchText}
          pageNumber={pageNumber}
          deleteCallbackMethod={confirmDeleteQRCode}
          enableRowSelection
        />
        <DownloadModel id={selectedId} open={openModal} closeModel={closeModel} name={selectedName} />
      </div>
    );
  }
  return (
    <div className="px-16">
      <TagPopup
        openTagModal={openTagModal}
        handleTagPopupCancel={handleTagPopupCancel}
        qrCodeId={qrCodeId}
        fetchData={fetchData}
        tableRequestParam={tableRequestParam}
      />
      <MailPopup
        isShow={showMailPopup}
        handleMailPopupCancel={handleMailPopupCancel}
        isMail={isMail}
        qrCodeId={qrCodeId}
        fetchData={fetchData}
        tableRequestParam={tableRequestParam}
      />
      <ShareQRCode
        urlForShare={urlForShare}
        isShow={isShow}
        handleCancel={handleCancel}
      />

      <PageHeader
        title="QR code"
        showSearch
        showAddButton={loginDetail?.profile?.UserRole?.toLowerCase() === DataConstant.userRole.Admin.toLowerCase() || loginDetail?.profile?.UserRole?.toLowerCase() === DataConstant.userRole.User.toLowerCase()}
        onClick={redirectToAddQRCode}
        description="QrPage"
        searchText={setSearchText}
      />
      <GridComponent
        key={refreshTable}
        rowKey="id"
        columns={columns}
        dataSource={qrCodeList.data}
        getGridData={getRequestObj}
        tableRequest={JSON.parse(JSON.stringify(tableRequestParam))}
        totalCount={qrCodeList.count}
        scroll={{ x: "max-content", y: "55vh" }}
        refresh={Refresh}
        SearchText={searchText}
        pageNumber={pageNumber}
        deleteCallbackMethod={confirmDeleteQRCode}
        enableRowSelection={loginDetail?.profile?.UserRole?.toLowerCase() === DataConstant.userRole.Admin.toLowerCase()}
      />
      <DownloadModel id={selectedId} open={openModal} closeModel={closeModel} name={selectedName} />
    </div>
  );

};

export default QRCode;
